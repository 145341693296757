import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaCalendarAlt, FaUser, FaArrowRight } from 'react-icons/fa';
import '../../../../styles/pages/home/sections/HomeBlogSection.css';

const mockPosts = [
  {
    id: 1,
    title: 'How AI is Revolutionizing Personal Productivity',
    excerpt: 'Discover the latest AI tools that are transforming the way we work and organize our time.',
    image: 'https://picsum.photos/800/600?random=1',
    author: 'DigitalHelper.No',
    date: 'Mar 15, 2024',
    category: 'Technology'
  },
  {
    id: 2,
    title: 'Ultimate Guide to Digital Organization in 2024',
    excerpt: 'Learn the best practices and tools to keep your digital files perfectly organized.',
    image: 'https://picsum.photos/800/600?random=2',
    author: 'DigitalHelper.No',
    date: 'Mar 10, 2024',
    category: 'Organization'
  },
  {
    id: 3,
    title: 'Web Design: Trends That Will Dominate This Year',
    excerpt: 'Explore the most innovative web design trends that are defining the digital experience.',
    image: 'https://picsum.photos/800/600?random=3',
    author: 'DigitalHelper.No',
    date: 'Mar 5, 2024',
    category: 'Design'
  }
];

const HomeBlogSection = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <section className="blog-section">
      <div className="blog-background-overlay"></div>
      <motion.div 
        className="section-container"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.2 }}
        variants={containerVariants}
      >
        <div className="section-header">
          <motion.h2 
            className="section-title"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.8,
                  ease: "easeOut"
                }
              }
            }}
          >
            Latest Articles from Our Blog
          </motion.h2>
          <motion.div 
            className="title-underline"
            variants={{
              hidden: { width: "0%" },
              visible: {
                width: "80px",
                transition: {
                  duration: 0.8,
                  ease: "easeOut",
                  delay: 0.3
                }
              }
            }}
          />
          <motion.p 
            className="section-subtitle"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.8,
                  delay: 0.4,
                  ease: "easeOut"
                }
              }
            }}
          >
            These are our most recent blog posts that you might find interesting.
          </motion.p>
        </div>

        <div className="blog-posts-container">
          {mockPosts.map((post) => (
            <motion.article 
              key={post.id} 
              className="blog-post"
              variants={itemVariants}
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <div className="post-image">
                <img src={post.image} alt={post.title} />
                <div className="post-category">{post.category}</div>
              </div>
              <div className="post-content">
                <div className="post-meta">
                  <span className="post-date">
                    <FaCalendarAlt /> {post.date}
                  </span>
                  <span className="post-author">
                    <FaUser /> {post.author}
                  </span>
                </div>
                <h3 className="post-title">{post.title}</h3>
                <p className="post-excerpt">{post.excerpt}</p>
                <div className="read-more-container">
                  <a href="#" className="read-more-button">
                    Read More <FaArrowRight className="arrow-icon" />
                  </a>
                </div>
              </div>
            </motion.article>
          ))}
        </div>

        <motion.div 
          className="view-all-container"
          variants={itemVariants}
        >
          <a href="#" className="view-all-button">
            View All Articles
            <FaArrowRight className="arrow-icon" />
          </a>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default HomeBlogSection;
