import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLinkedin, 
  faInstagram, 
  faFacebookF 
} from '@fortawesome/free-brands-svg-icons';
import { faComments, faLightbulb } from '@fortawesome/free-solid-svg-icons';

const FooterWrapper = styled.footer`
  background: linear-gradient(135deg, #0f172a 0%, #1e293b 100%);
  padding: 2rem 0;
  color: #ffffff;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
      transparent 0%,
      rgba(79, 172, 254, 0.5) 50%,
      transparent 100%
    );
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 50%, rgba(79, 172, 254, 0.05) 0%, transparent 50%),
      radial-gradient(circle at 80% 50%, rgba(0, 242, 254, 0.05) 0%, transparent 50%);
    pointer-events: none;
  }
`;

const FooterContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: #ffffff;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const Logo = styled.img`
  height: 50px;
  width: auto;
  filter: drop-shadow(0 0 10px rgba(255,255,255,0.2));
`;

const ChatButton = styled(Link)`
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(79, 172, 254, 0.3);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.2),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(79, 172, 254, 0.4);

    &::before {
      left: 100%;
    }

    .icon {
      transform: scale(1.1) rotate(5deg);
    }
  }

  .icon {
    font-size: 1.1rem;
    transition: transform 0.3s ease;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;

  a {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover {
      transform: translateY(-3px);
      
      &::before {
        opacity: 1;
      }

      svg {
        transform: scale(1.1) rotate(360deg);
      }
    }

    svg {
      position: relative;
      z-index: 1;
      transition: all 0.5s ease;
      font-size: 1.2rem;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Copyright = styled.div`
  font-size: 0.9rem;
  color: #94a3b8;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.25rem;
  }

  &::before,
  &::after {
    content: '';
    height: 1px;
    width: 20px;
    background: linear-gradient(90deg, transparent, #94a3b8, transparent);

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const SloganContainer = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
  padding: 1.5rem 0;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 1px;
    background: linear-gradient(90deg, 
      transparent 0%,
      rgba(79, 172, 254, 0.8) 50%,
      transparent 100%
    );
  }
`;

const SloganIcon = styled(FontAwesomeIcon)`
  color: #4facfe;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.8;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.8;
    }
  }
`;

const SloganText = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-bottom: 0.5rem;
  
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SloganSubtext = styled.p`
  color: #94a3b8;
  font-size: 1.1rem;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
  
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 0 1rem;
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <SloganContainer>
        <SloganIcon icon={faLightbulb} />
        <SloganText>Transforming Ideas into Digital Realities</SloganText>
        <SloganSubtext>
          We boost your productivity with technological solutions that drive your business growth
        </SloganSubtext>
      </SloganContainer>
      <FooterContent>
        <LogoContainer to="/">
          <Logo 
            src="/assets/images/Digital-Helper-No-Portrait-White-Logo.png"
            alt="DigitalHelper Logo" 
          />
        </LogoContainer>

        <ChatButton to="/contact">
          <FontAwesomeIcon icon={faComments} className="icon" />
          <span>Chat with an Advisor</span>
        </ChatButton>

        <SocialLinks>
          <a href="https://linkedin.com/company/digitalhelper-no" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="https://instagram.com/digitalhelper.no" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://facebook.com/digitalhelperno" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        </SocialLinks>

        <Copyright>
          &copy; {new Date().getFullYear()} DigitalHelper.no
        </Copyright>
      </FooterContent>
    </FooterWrapper>
  );
}

export default Footer;
