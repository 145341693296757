import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faComments } from '@fortawesome/free-solid-svg-icons';

const HeaderContainer = styled(motion.header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: linear-gradient(90deg, rgba(13,17,23,0.98) 0%, rgba(22,27,34,0.98) 100%);
  height: 80px;
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 20px rgba(0,0,0,0.1);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, #0066ff, #2563eb, #0066ff);
    opacity: 0.5;
  }
`;

const Nav = styled.nav`
  max-width: 1400px;
  margin: 0 auto;
  height: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoContainer = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.5rem 0;
  width: 200px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const Logo = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;
  filter: drop-shadow(0 0 10px rgba(255,255,255,0.1));
`;

const MenuItems = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  position: relative;
  transition: all 0.3s ease;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background: linear-gradient(90deg, #0066ff, #2563eb);
    transition: width 0.3s ease;
  }

  &:hover {
    color: #2563eb;
    &::after {
      width: 80%;
    }
  }

  &.active {
    color: #2563eb;
    &::after {
      width: 80%;
    }
  }
`;

const CTAButton = styled(Link)`
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.15);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #60a5fa 0%, #3b82f6 100%);
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 24px rgba(37, 99, 235, 0.25);

    &::before {
      opacity: 1;
    }

    .icon {
      transform: scale(1.15) rotate(5deg);
    }
  }

  &:active {
    transform: translateY(1px);
    box-shadow: 0 2px 8px rgba(37, 99, 235, 0.2);
    background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);

    &::before {
      opacity: 0;
    }

    .icon {
      transform: scale(0.95);
    }
  }

  .icon {
    font-size: 1rem;
    position: relative;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    color: rgba(255, 255, 255, 0.95);
  }

  span {
    position: relative;
    z-index: 1;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    display: block;
  }

  &:hover {
    color: #2563eb;
  }
`;

const MobileMenu = styled(motion.div)`
  display: none;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  background: rgba(13,17,23,0.98);
  backdrop-filter: blur(10px);
  padding: 2rem;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
`;

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <HeaderContainer
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        background: scrolled
          ? 'rgba(13,17,23,0.98)'
          : 'linear-gradient(90deg, rgba(13,17,23,0.98) 0%, rgba(22,27,34,0.98) 100%)',
      }}
    >
      <Nav>
        <LogoContainer to="/">
          <Logo 
            src="/assets/images/Digital-Helper-No-Portrait-White-Logo.png"
            alt="DigitalHelper Logo" 
          />
        </LogoContainer>

        <MenuItems>
          <NavLink to="/" className={location.pathname === '/' ? 'active' : ''}>
            Home
          </NavLink>
          <NavLink to="/servicios" className={location.pathname === '/servicios' ? 'active' : ''}>
            Services
          </NavLink>
          <NavLink to="/blog" className={location.pathname === '/blog' ? 'active' : ''}>
            Blog
          </NavLink>
          <CTAButton to="/contacto">
            <FontAwesomeIcon icon={faComments} className="icon" />
            <span>Chat with an Advisor Now</span>
          </CTAButton>
        </MenuItems>

        <MobileMenuButton onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </MobileMenuButton>

        <AnimatePresence>
          {isOpen && (
            <MobileMenu
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <NavLink to="/" onClick={() => setIsOpen(false)}>
                Home
              </NavLink>
              <NavLink to="/servicios" onClick={() => setIsOpen(false)}>
                Services
              </NavLink>
              <NavLink to="/blog" onClick={() => setIsOpen(false)}>
                Blog
              </NavLink>
              <CTAButton to="/contacto" onClick={() => setIsOpen(false)}>
                <FontAwesomeIcon icon={faComments} className="icon" />
                <span>Chat with an Advisor Now</span>
              </CTAButton>
            </MobileMenu>
          )}
        </AnimatePresence>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
