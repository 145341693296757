import React from 'react';
import styled from 'styled-components';

const FAQContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const FAQ = () => {
  return (
    <FAQContainer>
      <h1>Preguntas Frecuentes</h1>
      <p>Página en construcción...</p>
    </FAQContainer>
  );
};

export default FAQ;
