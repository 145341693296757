import React, { useEffect, useRef } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faGears, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import '../styles/pages/UnderConstruction.css';

const FeatureCard = ({ icon, title, description, index }) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [controls, isInView]);

  return (
    <motion.div
      ref={ref}
      className="feature"
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            delay: index * 0.2,
            ease: [0.6, -0.05, 0.01, 0.99]
          }
        }
      }}
    >
      <div className="feature-icon-wrapper">
        <FontAwesomeIcon icon={icon} className="feature-icon" />
        <div className="feature-icon-bg"></div>
      </div>
      <motion.h3
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 + index * 0.2 }}
      >
        {title}
      </motion.h3>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 + index * 0.2 }}
      >
        {description}
      </motion.p>
      <div className="feature-decoration"></div>
    </motion.div>
  );
};

const UnderConstruction = () => {
  const features = [
    {
      icon: faCode,
      title: "Digital Innovation",
      description: "Creating seamless digital experiences with cutting-edge technology and intuitive design."
    },
    {
      icon: faGears,
      title: "Smart Solutions",
      description: "Empowering your digital journey with intelligent, efficient, and scalable solutions."
    },
    {
      icon: faWandMagicSparkles,
      title: "Future Ready",
      description: "Preparing your business for tomorrow with innovative digital transformation strategies."
    }
  ];

  // Generar partículas con propiedades aleatorias
  const generateParticles = () => {
    return Array.from({ length: 100 }, (_, i) => ({
      id: i,
      size: Math.random() * 3 + 1, // Tamaño entre 1 y 4px
      speed: Math.random() * 20 + 10, // Velocidad entre 10 y 30 segundos
      delay: Math.random() * -30, // Delay negativo para empezar en posiciones aleatorias
      brightness: Math.random() * 0.7 + 0.3, // Brillo entre 0.3 y 1
      type: Math.random() > 0.9 ? 'star' : 'particle', // 10% de probabilidad de ser una estrella
      pulseSpeed: Math.random() * 2 + 1, // Velocidad de pulsación entre 1 y 3 segundos
      xOffset: Math.random() * 100, // Posición X inicial aleatoria
      yOffset: Math.random() * 100, // Posición Y inicial aleatoria
    }));
  };

  return (
    <div className="construction-container">
      <div className="animated-background">
        <div className="stars-overlay"></div>
        <div className="nebula-overlay"></div>
        {generateParticles().map((particle) => (
          <div
            key={particle.id}
            className={`particle ${particle.type}`}
            style={{
              '--size': `${particle.size}px`,
              '--speed': `${particle.speed}s`,
              '--delay': `${particle.delay}s`,
              '--brightness': particle.brightness,
              '--pulse-speed': `${particle.pulseSpeed}s`,
              '--x-offset': `${particle.xOffset}%`,
              '--y-offset': `${particle.yOffset}%`,
            }}
          />
        ))}
      </div>
      <motion.div 
        className="construction-content"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.8,
          ease: [0.6, -0.05, 0.01, 0.99]
        }}
      >
        <motion.div
          className="logo-container"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, duration: 0.8 }}
        >
          <img 
            src="/assets/images/Digital-Helper-No-Portrait-White-Logo.png" 
            alt="DigitalHelper.no Logo" 
            className="main-logo"
          />
        </motion.div>

        <motion.h1
          className="main-heading"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          Transforming Digital Experiences
        </motion.h1>

        <motion.p
          className="sub-heading"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7, duration: 0.8 }}
        >
          We're crafting something extraordinary for you
        </motion.p>

        <div className="features-grid">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
              index={index}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default UnderConstruction;
