import React, { useEffect, useState, useRef } from 'react';
import { motion, useScroll, useTransform, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faComments, faSearch, faCode, faMagic } from '@fortawesome/free-solid-svg-icons';
import '../../../../styles/pages/home/sections/HomeHeroSection.css';

const HomeHeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const videoRef = useRef(null);
  const sectionRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start start", "end start"]
  });

  const videoScale = useTransform(scrollYProgress, [0, 1], [1, 1.15]);
  const videoY = useTransform(scrollYProgress, [0, 1], ['0%', '10%']);
  const overlayOpacity = useTransform(scrollYProgress, [0, 1], [0.6, 0.8]);
  const titleY = useTransform(scrollYProgress, [0, 1], [0, 100]);

  useEffect(() => {
    setIsVisible(true);
    const typeText = async () => {
      const service = services[currentServiceIndex];
      
      if (isTyping) {
        for (let i = 0; i <= service.length; i++) {
          setDisplayText(service.slice(0, i));
          await new Promise(resolve => setTimeout(resolve, 50));
        }
        setTimeout(() => setIsTyping(false), 2000);
      } else {
        for (let i = service.length; i >= 0; i--) {
          setDisplayText(service.slice(0, i));
          await new Promise(resolve => setTimeout(resolve, 30));
        }
        setCurrentServiceIndex((prev) => (prev + 1) % services.length);
        setIsTyping(true);
      }
    };

    typeText();
  }, [currentServiceIndex, isTyping]);

  const services = [
    "Professional Presentations ",
    "Social Media Designs ",
    "Custom Digital Organization ",
    "AI Automation and Bots ",
    "WordPress Websites "
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.8,
        staggerChildren: 0.3
      }
    }
  };

  const titleVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 1,
        type: "spring",
        stiffness: 100
      }
    }
  };

  const glowVariants = {
    initial: { scale: 1, opacity: 0.5 },
    animate: {
      scale: [1, 1.2, 1],
      opacity: [0.5, 0.8, 0.5],
      transition: {
        duration: 2,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  const floatingIconsVariants = {
    initial: { y: 0 },
    animate: {
      y: [-10, 10, -10],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <section className="hero-section" ref={sectionRef}>
      <motion.div 
        className="hero-video-container"
        style={{
          scale: videoScale,
          y: videoY
        }}
      >
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          className="hero-video"
        >
          <source src="/assets/videos/video-tecnology-Digital-Helper.mp4" type="video/mp4" />
        </video>
        <motion.div 
          className="hero-overlay"
          style={{ opacity: overlayOpacity }}
        />
      </motion.div>

      <div className="hero-content">
        <motion.div 
          className="title-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="hero-title">
            <span className="hero-title-text">
              At{' '}
              <motion.span 
                className="company-name"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3, duration: 0.8 }}
              >
                DIGITALHELPER.NO
              </motion.span>
            </span>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 0.8 }}
            >
              we help you with:
            </motion.span>
          </h1>
        </motion.div>

        <motion.div 
          className="search-bar-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          <div className="search-bar">
            <motion.div
              className="search-icon-container"
              animate={{
                rotate: [0, 360],
                scale: [1, 1.2, 1]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "linear"
              }}
            >
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
            </motion.div>
            <span className="typing-text">{displayText}</span>
            <span className="cursor">|</span>
          </div>
        </motion.div>

        <motion.div
          className="hero-slogan"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          We transform complexity into simplicity. Smart technological solutions that optimize your time and boost your productivity.
        </motion.div>

        <motion.div 
          className="hero-buttons"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8, duration: 0.8 }}
        >
          <motion.button 
            className="hero-button primary"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FontAwesomeIcon icon={faRocket} className="button-icon" />
            Get Started
          </motion.button>
          
          <motion.button 
            className="hero-button secondary"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FontAwesomeIcon icon={faComments} className="button-icon" />
            Contact Us
          </motion.button>
        </motion.div>

        <motion.div 
          className="scroll-indicator"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.8 }}
          onClick={() => {
            const nextSection = document.querySelector('.services-section');
            if (nextSection) {
              nextSection.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <span>Explore More</span>
          <div className="mouse">
            <div className="wheel"></div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default HomeHeroSection;
