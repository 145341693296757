import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCloud, faFolderOpen, faMobileAlt, 
  faDesktop, faImage, faCamera, 
  faRobot, faCode, faGlobe,
  faArrowRight, faComments, faRocket
} from '@fortawesome/free-solid-svg-icons';
import '../../../../styles/pages/home/sections/HomeServicesSection.css';

const HomeServicesSection = () => {
  const serviceAreas = [
    {
      id: "organization",
      title: "Digital Organization and Backup",
      subtitle: "Your information, secure and accessible",
      description: "We protect and organize your digital data with professional solutions that ensure the security and accessibility of your information.",
      icon: faCloud,
      color: "#3498db",
      iconColor: "#3498db",
      services: [
        {
          name: "Secure Digital Backup",
          description: "Transfer and protect your data in the cloud with maximum security.",
          icon: faCloud,
          link: "/services/digital-backup"
        },
        {
          name: "Document Digitization",
          description: "We convert your physical documents into organized digital files.",
          icon: faFolderOpen,
          link: "/services/digitization"
        },
        {
          name: "Device Configuration",
          description: "Professional setup and assistance for your technological devices.",
          icon: faMobileAlt,
          link: "/services/configuration"
        }
      ]
    },
    {
      id: "design",
      title: "Design and Visual Content",
      subtitle: "Make an impact with professional content",
      description: "We create visual content that highlights your message and connects with your audience, from presentations to social media designs.",
      icon: faDesktop,
      color: "#e74c3c",
      iconColor: "#e74c3c",
      services: [
        {
          name: "Professional Presentations",
          description: "Impactful presentations that effectively convey your message.",
          icon: faDesktop,
          link: "/services/presentations"
        },
        {
          name: "Social Media Design",
          description: "Attractive and strategic visual content for your social networks.",
          icon: faImage,
          link: "/services/social-media-design"
        },
        {
          name: "Photo Digitization",
          description: "Restoration and digital preservation of your most valuable photographs.",
          icon: faCamera,
          link: "/services/photo-digitization"
        }
      ]
    },
    {
      id: "automation",
      title: "Web and Automation",
      subtitle: "Smart digital solutions",
      description: "We develop modern websites and automation solutions that optimize your digital presence and improve your efficiency.",
      icon: faCode,
      color: "#2ecc71",
      iconColor: "#2ecc71",
      services: [
        {
          name: "Custom Web Development",
          description: "Modern and optimized websites that showcase your brand.",
          icon: faCode,
          link: "/services/web-development"
        },
        {
          name: "AI Automation",
          description: "Bots and automated processes that enhance your efficiency.",
          icon: faRobot,
          link: "/services/automation"
        },
        {
          name: "Strategic Web Content",
          description: "Content that connects with your audience and improves your visibility.",
          icon: faGlobe,
          link: "/services/web-content"
        }
      ]
    }
  ];

  const title = "How We Provide Solutions for Your Digital World";
  const description = "We help people like you, entrepreneurs and small businesses with personalized digital solutions that boost your productivity.";

  const titleVariants = {
    hidden: { 
      opacity: 0,
      y: 30,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
        duration: 0.8
      }
    }
  };

  const descriptionVariants = {
    hidden: { 
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
        delay: 0.2,
        duration: 0.6
      }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const areaVariants = {
    hidden: { 
      opacity: 0,
      y: 50,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12,
        duration: 0.8
      }
    }
  };

  const iconVariants = {
    hidden: { 
      scale: 0.8,
      opacity: 0,
      rotate: -15
    },
    visible: {
      scale: 1,
      opacity: 1,
      rotate: 0,
      transition: {
        type: "spring",
        stiffness: 200,
        damping: 15,
        duration: 0.6
      }
    },
    hover: {
      scale: 1.1,
      rotate: [0, -10, 10, -5, 5, 0],
      transition: {
        duration: 0.6,
        times: [0, 0.2, 0.4, 0.6, 0.8, 1],
        type: "spring",
        stiffness: 300
      }
    }
  };

  const serviceVariants = {
    hidden: { x: -20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };

  return (
    <section className="services-section">
      <div className="services-background"></div>
      <div className="services-content">
        <div className="title-container">
          <motion.h2 
            className="section-title"
            variants={titleVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            {title}
          </motion.h2>
          <motion.p 
            className="section-description"
            variants={descriptionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            {description}
          </motion.p>
        </div>
        
        <motion.div
          className="services-container"
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          {serviceAreas.map((area, index) => (
            <motion.div
              key={area.id}
              className="service-area-card"
              data-area={area.id}
              variants={areaVariants}
              whileHover={{ 
                y: -10,
                transition: { 
                  type: "spring",
                  stiffness: 300,
                  damping: 15 
                }
              }}
            >
              <div className="card-content">
                <div className="area-header">
                  <motion.div
                    className="area-icon"
                    variants={iconVariants}
                    initial="hidden"
                    whileInView="visible"
                    whileHover="hover"
                    viewport={{ once: true }}
                    style={{ backgroundColor: area.iconColor }}
                  >
                    <FontAwesomeIcon icon={area.icon} />
                  </motion.div>
                  <h3>{area.title}</h3>
                  <span className="area-subtitle">{area.subtitle}</span>
                  <p className="area-description">{area.description}</p>
                </div>
                <div className="services-list">
                  {area.services.map((service) => (
                    <motion.div
                      key={service.name}
                      className="service-item"
                      variants={serviceVariants}
                    >
                      <Link to={service.link}>
                        <div className="service-icon">
                          <FontAwesomeIcon icon={service.icon} style={{ color: area.color }} />
                        </div>
                        <div className="service-info">
                          <h4>{service.name}</h4>
                          <p>{service.description}</p>
                        </div>
                      </Link>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
          
          {/* CTA Card */}
          <motion.div
            className="cta-card"
            variants={areaVariants}
          >
            <div className="cta-content">
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                className="cta-icon"
              >
                <FontAwesomeIcon icon={faRocket} />
              </motion.div>
              <h2 className="cta-title">Ready to Get Started?</h2>
              <p className="cta-description">
                We want to help you boost your digital productivity, talk to us to discover how we can help you.
              </p>
              <motion.div 
                className="cta-buttons"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2, duration: 0.5 }}
              >
                <Link to="/contact" className="cta-button primary">
                  Talk to an Advisor
                  <FontAwesomeIcon icon={faComments} className="button-icon" />
                </Link>
                <Link to="/services" className="cta-button secondary">
                  View all services
                  <FontAwesomeIcon icon={faArrowRight} className="button-icon" />
                </Link>
              </motion.div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default HomeServicesSection;
