import React from 'react';
import HomeHeroSection from '../components/pages/home/sections/HomeHeroSection';
import HomeServicesSection from '../components/pages/home/sections/HomeServicesSection';
import HomeHowWeWorkSection from '../components/pages/home/sections/HomeHowWeWorkSection';
import HomeTestimonialsSection from '../components/pages/home/sections/HomeTestimonialsSection';
import HomeBlogSection from '../components/pages/home/sections/HomeBlogSection';
import '../styles/pages/home/Home.css';

// Datos para las secciones de servicios
const serviceAreas = [
  {
    id: "organization",
    title: "Organización y Respaldo Digital",
    services: [
      {
        name: "Respaldo Digital Seguro",
        description: "Transferencia y protección de tus datos en la nube",
        icon: "faCloud"
      },
      {
        name: "Configuración de Dispositivos",
        description: "Configuración y acceso seguro a tus archivos",
        icon: "faLaptop"
      },
      {
        name: "Soporte Personalizado",
        description: "Asistencia continua para tus necesidades digitales",
        icon: "faHeadset"
      }
    ]
  },
  {
    id: "design",
    title: "Diseño y Contenido Visual",
    services: [
      {
        name: "Edición Profesional de Fotos",
        description: "Mejora y restauración de imágenes",
        icon: "faImage"
      },
      {
        name: "Diseño de Presentaciones",
        description: "Presentaciones impactantes para tu negocio",
        icon: "faDesktop"
      },
      {
        name: "Diseño Gráfico Personalizado",
        description: "Creación de contenido visual atractivo",
        icon: "faPalette"
      }
    ]
  },
  {
    id: "web",
    title: "Web y Automatización",
    services: [
      {
        name: "Desarrollo Web Personalizado",
        description: "Sitios web modernos y optimizados",
        icon: "faCode"
      },
      {
        name: "Automatización con IA",
        description: "Bots y procesos automatizados inteligentes",
        icon: "faRobot"
      },
      {
        name: "Contenido Web Estratégico",
        description: "Contenido que conecta y convierte",
        icon: "faPen"
      }
    ]
  }
];

// Datos para la sección de cómo trabajamos
const workSteps = [
  {
    id: 1,
    title: "Evaluamos tus Necesidades",
    description: "Analizamos tus requerimientos para ofrecer soluciones a medida",
    icon: "faSearch"
  },
  {
    id: 2,
    title: "Ejecutamos el Proyecto",
    description: "Implementamos las soluciones con profesionalismo y eficiencia",
    icon: "faCogs"
  },
  {
    id: 3,
    title: "Entrega y Soporte",
    description: "Entregamos resultados y ofrecemos soporte continuo",
    icon: "faHandshake"
  }
];

// Datos para la sección de testimonios
const testimonials = [
  {
    id: 1,
    content: "DigitalHelper.no transformó mi caos digital en un sistema ordenado y eficiente",
    author: "Ingrid Larsen",
    avatar: "/images/testimonials/ingrid.jpg"
  },
  {
    id: 2,
    content: "Las presentaciones profesionales marcaron la diferencia en mis reuniones de negocios",
    author: "Erik Johansen",
    avatar: "/images/testimonials/erik.jpg"
  },
  {
    id: 3,
    content: "Gracias a su automatización, mi negocio ahorra tiempo cada día",
    author: "Maria Hansen",
    avatar: "/images/testimonials/maria.jpg"
  }
];

// Datos para la sección del blog
const blogPosts = [
  {
    id: 1,
    title: "5 Tips para Organizar tu Vida Digital",
    excerpt: "Simplifica y gestiona mejor tus archivos",
    image: "/images/blog/organization-tips.jpg"
  },
  {
    id: 2,
    title: "El Poder de la Automatización en Pequeñas Empresas",
    excerpt: "Impulsa la eficiencia con IA",
    image: "/images/blog/automation-power.jpg"
  },
  {
    id: 3,
    title: "Diseño de Presentaciones que Impactan",
    excerpt: "Crea presentaciones efectivas",
    image: "/images/blog/presentation-design.jpg"
  }
];

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-sections-container">
        <HomeHeroSection />
        <HomeServicesSection serviceAreas={serviceAreas} />
        <HomeHowWeWorkSection steps={workSteps} />
        <HomeTestimonialsSection testimonials={testimonials} />
        <HomeBlogSection posts={blogPosts} />
      </div>
    </div>
  );
};

export default Home;