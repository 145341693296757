import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faStar } from '@fortawesome/free-solid-svg-icons';
import '../../../../styles/pages/home/sections/HomeTestimonialsSection.css';

const testimonials = [
  {
    id: 1,
    name: 'Emma Larsen',
    role: 'Fashion Advisor',
    service: 'Automatic Scheduling Bot',
    image: '/assets/images/testimonials/emma.jpg',
    text: 'The scheduling bot that DigitalHelper developed for my business has revolutionized how I manage my appointments. Now my clients can schedule consulting sessions 24/7, and I can focus on what I\'m most passionate about: helping them with their personal style. The automation has increased my bookings by 40%.',
    rating: 5
  },
  {
    id: 2,
    name: 'Anders Nielsen',
    role: 'Business Consultant',
    service: 'Executive Presentations Design',
    image: '/assets/images/testimonials/anders.jpg',
    text: 'I needed a presentation that would make an impact at my upcoming international conference. The team not only created an exceptional design but also helped me structure the content in a way that captured my audience\'s attention from the very beginning. The result was incredible, and now all my clients want similar presentations.',
    rating: 5
  },
  {
    id: 3,
    name: 'Sofia Berg',
    role: 'Professional Photographer',
    service: 'Cloud Digital Backup',
    image: '/assets/images/testimonials/sofia.jpg',
    text: 'As a professional photographer, I had years of work spread across various external hard drives. DigitalHelper not only helped me consolidate and organize over 15 years of photographs into a secure cloud system but also implemented an automated process to back up my new work. The peace of mind of having everything organized and secure is priceless.',
    rating: 5
  }
];

const TestimonialCard = ({ testimonial, index }) => {
  return (
    <motion.div 
      className="testimonial-card"
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      viewport={{ once: true }}
    >
      <div className="testimonial-quote-icon">
        <FontAwesomeIcon icon={faQuoteLeft} />
      </div>
      <div className="testimonial-content">
        <p>{testimonial.text}</p>
        <div className="testimonial-footer">
          <div className="testimonial-service">
            <span>{testimonial.service}</span>
          </div>
          <div className="testimonial-rating">
            {[...Array(testimonial.rating)].map((_, i) => (
              <FontAwesomeIcon key={i} icon={faStar} className="star-icon" />
            ))}
          </div>
        </div>
      </div>
      <div className="testimonial-author">
        <img src={testimonial.image} alt={testimonial.name} className="testimonial-avatar" />
        <div className="testimonial-info">
          <h4>{testimonial.name}</h4>
          <p>{testimonial.role}</p>
        </div>
      </div>
    </motion.div>
  );
};

const HomeTestimonialsSection = () => {
  return (
    <motion.section 
      className="home-testimonials-section"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
    >
      <div className="testimonials-content">
        <motion.h2 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="section-title"
        >
          What Our Clients Say
        </motion.h2>
        <motion.p 
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
          className="section-description"
        >
          Real stories of digital transformation that have improved our clients' lives
        </motion.p>
        <div className="testimonials-grid">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard 
              key={testimonial.id} 
              testimonial={testimonial} 
              index={index}
            />
          ))}
        </div>
      </div>
    </motion.section>
  );
};

export default HomeTestimonialsSection;
