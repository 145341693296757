import React from 'react';
import styled from 'styled-components';

const AboutContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const About = () => {
  return (
    <AboutContainer>
      <h1>Sobre Nosotros</h1>
      <p>Página en construcción...</p>
    </AboutContainer>
  );
};

export default About;
