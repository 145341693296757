import React from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faComments,
  faFileInvoiceDollar,
  faLightbulb,
  faRocket,
  faArrowRight,
  faArrowDown
} from '@fortawesome/free-solid-svg-icons';
import '../../../../styles/pages/home/sections/HomeHowWeWorkSection.css';

const steps = [
  {
    number: '1.',
    title: 'Initial Consultation',
    description: 'We listen to your needs and objectives to fully understand your vision.',
    icon: faComments,
    gradient: 'linear-gradient(135deg, #4facfe 0%, #00f2fe 100%)'
  },
  {
    number: '2.',
    title: 'Immediate Quote',
    description: 'Receive a clear and detailed proposal directly in your email.',
    icon: faFileInvoiceDollar,
    gradient: 'linear-gradient(135deg, #7f7fd5 0%, #86a8e7 100%)'
  },
  {
    number: '3.',
    title: 'Planning',
    description: 'We develop a personalized strategy that adapts to your specific goals.',
    icon: faLightbulb,
    gradient: 'linear-gradient(135deg, #00c6fb 0%, #005bea 100%)'
  },
  {
    number: '4.',
    title: 'Implementation',
    description: 'We execute the solution using the best current practices and technologies.',
    icon: faRocket,
    gradient: 'linear-gradient(135deg, #34c759 0%, #2ecc71 100%)'
  }
];

const HomeHowWeWorkSection = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <section className="how-we-work-section">
      <div className="section-container">
        <motion.div 
          className="title-container"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="section-title">
            How We Work at{' '}
            <motion.span
              className="highlight"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
            >
              DigitalHelper.No
            </motion.span>
          </h2>
          <motion.p
            className="section-subtitle"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            We have a procedure to work with you and maximize efficiency,
            <span className="highlight-text"> ensuring exceptional results in every project</span>
          </motion.p>
        </motion.div>

        <div className="steps-grid">
          {steps.map((step, index) => (
            <motion.div
              key={step.number}
              className="step-card"
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0.5,
                delay: index * 0.2,
                ease: "easeOut"
              }}
              whileHover={{ 
                scale: 1.02,
                transition: { duration: 0.2 }
              }}
            >
              <div 
                className="step-icon-container"
                style={{ background: step.gradient }}
              >
                <motion.div
                  className="step-icon"
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.8, ease: "easeInOut" }}
                >
                  <FontAwesomeIcon icon={step.icon} />
                </motion.div>
              </div>
              
              <div className="step-content">
                <div 
                  className="step-number"
                  style={{ 
                    background: step.gradient,
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent'
                  }}
                >
                  {step.number}
                </div>
                <h3 className="step-title">{step.title}</h3>
                <p className="step-description">{step.description}</p>
              </div>

              {index < steps.length - 1 && (
                <motion.div 
                  className="step-arrow"
                  initial={{ opacity: 0, x: isMobile ? 0 : -10, y: isMobile ? -10 : 0 }}
                  animate={{ opacity: 1, x: 0, y: 0 }}
                  transition={{ delay: 0.5 + index * 0.2 }}
                >
                  <FontAwesomeIcon icon={isMobile ? faArrowDown : faArrowRight} />
                </motion.div>
              )}
            </motion.div>
          ))}
        </div>

        <motion.div 
          className="cta-container"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <motion.a
            href="/contacto"
            className="cta-button"
            whileHover={{ 
              scale: 1.05,
              boxShadow: "0 8px 30px rgba(79, 172, 254, 0.3)"
            }}
            whileTap={{ scale: 0.95 }}
          >
            <span>Consult Your Service Now</span>
            <FontAwesomeIcon icon={faArrowRight} className="cta-icon" />
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default HomeHowWeWorkSection;
