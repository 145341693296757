import React from 'react';
import styled from 'styled-components';

const ContactContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Contact = () => {
  return (
    <ContactContainer>
      <h1>Contacto</h1>
      <p>Página en construcción...</p>
    </ContactContainer>
  );
};

export default Contact;
