import React from 'react';
import styled from 'styled-components';

const PrivacyContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const Privacy = () => {
  return (
    <PrivacyContainer>
      <h1>Política de Privacidad</h1>
      <p>Página en construcción...</p>
    </PrivacyContainer>
  );
};

export default Privacy;
